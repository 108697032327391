<template>
    <div class="d-flex all-after-header-box">

        <div class="content-box marg-l-10">

            Main Page

        </div>

    </div>
</template>

<script>

export default {
    name: "MainPage",
    computed: {
    },
    methods: {
    }
}
</script>